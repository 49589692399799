import React from "react"
import Helmet from "react-helmet"

import "./normalize.css"
import "./index.scss"

const SCHEDULE_IMAGE_URL = "https://willcosgrove.com/schedule.png"

const TemplateWrapper = ({ children }) => (
  <div
    css={{
      display: "grid",
      gridTemplateRows: "100px 1fr",
      gridTemplateColumns:
        "minmax(20px, 1fr) minmax(200px, 600px) minmax(20px, 1fr)",
      marginBottom: 100,
    }}
  >
    <Helmet
      title="Will Cosgrove"
      meta={[
        {
          name: "description",
          content: "Hey I'm Will, it's nice to meet you!",
        },
        {
          name: "keywords",
          content:
            "software development, rails, ruby on rails, application development",
        },
      ]}
    >
      <link
        href="https://fonts.googleapis.com/css?family=Fira+Sans"
        rel="stylesheet"
      />
      <meta name="og:title" content="Will Cosgrove" />
      <meta name="twitter:title" content="Will Cosgrove" />
      <meta name="og:image" content={SCHEDULE_IMAGE_URL} />
      <meta name="twitter:image" content={SCHEDULE_IMAGE_URL} />
      <meta name="og:url" content="https://willcosgrove.com/" />
      <meta
        name="og:description"
        content="Hey I'm Will, it's nice to meet you!"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@willcosgrove" />
    </Helmet>
    {children}
  </div>
)

export default TemplateWrapper
