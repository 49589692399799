import React from "react"
import axios from "axios"
import md5 from "md5"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default class SubscribeForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      subscribedEmailHashes: new Set([]),
      email: "",
      submitting: false,
      submitted: false,
      removed: false,
      failed: false,
    }
    this.form = React.createRef()
    this.fetchSubscribedEmails()
  }

  async fetchSubscribedEmails() {
    try {
      const response = await axios.get("/.netlify/functions/subscribed_emails")
      if (response.status === 200) {
        this.setState({ subscribedEmailHashes: new Set(response.data) })
      }
    } catch (e) {}
  }

  render() {
    const { email, submitted, removed, failed } = this.state
    return (
      <React.Fragment>
        <p css={{ textAlign: "center" }}>
          {failed ? (
            <React.Fragment>
              Oh no, this is embarassing. Something didn't work right. If you{" "}
              <a href="mailto:will@willcosgrove.com">shoot me an email</a> I'll
              get you taken care of.
            </React.Fragment>
          ) : submitted ? (
            removed ? (
              "You're all set! I'm sorry to see you go 👋"
            ) : (
              "You're all set! Thank you so much for your interest 🙏"
            )
          ) : (
            "If you would like to be notified of any openings in my schedule, you can sign up here."
          )}
        </p>
        {submitted || failed ? null : (
          <form
            name="openings"
            ref={this.form}
            css={{ display: "flex", flexDirection: "column" }}
            netlify=""
            onSubmit={this.handleFormSubmit}
          >
            <input type="hidden" name="form-name" value="openings" />
            <input
              name="email"
              type="email"
              value={email}
              onChange={this.handleEmailChange}
              placeholder="email"
            />
            {this.renderSubmitButton()}
          </form>
        )}
      </React.Fragment>
    )
  }

  renderSubmitButton() {
    const { submitting } = this.state
    const subscribed = this.alreadySubscribed()
    return (
      <React.Fragment>
        <button
          className={classNames({ danger: subscribed, primary: !subscribed })}
          css={{ marginTop: 20 }}
          disabled={submitting || (!subscribed && !this.validEmail())}
          onClick={this.handleFormSubmit}
        >
          {submitting ? (
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          ) : subscribed ? (
            "Take me off the list please"
          ) : (
            "Please let me know if anything opens up"
          )}
        </button>
        {subscribed ? (
          <p className="aside" css={{ marginBottom: 0 }}>
            It looks like you're already on the list
          </p>
        ) : null}
      </React.Fragment>
    )
  }

  alreadySubscribed() {
    return this.state.subscribedEmailHashes.has(
      md5(this.state.email.toLowerCase())
    )
  }

  validEmail() {
    return EMAIL_REGEX.test(this.state.email)
  }

  handleFormSubmit = e => {
    e.preventDefault()
    if (!this.validEmail()) return

    this.setState({ submitting: true })
    if (this.alreadySubscribed()) {
      axios
        .post("/.netlify/functions/unsubscribe", { email: this.state.email })
        .then(req => {
          this.setState({ submitting: false, submitted: true, removed: true })
        })
        .catch(() => {
          this.setState({ submitting: false, failed: true })
        })
    } else {
      axios
        .post("/hire-me", new FormData(this.form.current))
        .then(req => {
          this.setState({ submitting: false, submitted: true })
        })
        .catch(() => {
          this.setState({ submitting: false, failed: true })
        })
    }
  }

  handleEmailChange = e => {
    this.setState({ email: e.target.value })
  }
}
