import scheduleJSON from "../../schedule.json"
import {
  getISOYear,
  getISOWeek,
  addWeeks,
  format,
  getMonth,
  isThisISOYear,
} from "date-fns"

export const clientColors = [
  "#54E3CA",
  "#AD82D9",
  "#EDC17E",
  "#58A9F3",
  "#434C5F",
  "#CE434B",
]

let schedule = scheduleJSON

for (const year in schedule) {
  for (const client in schedule[year].clients) {
    const clientColor =
      clientColors[Math.floor(Math.random() * clientColors.length)]
    for (const workRange of schedule[year].clients[client]) {
      for (let i = workRange[0]; i <= workRange[1]; i++) {
        schedule[year][i] = { booked: true, color: clientColor }
      }
    }
  }
}

export const isBooked = week => {
  const scheduleWeek = getScheduleForWeek(week)
  return scheduleWeek && scheduleWeek.booked
}

export const isAvailable = week => !isBooked(week)

export const getScheduleForWeek = week => {
  const year = schedule[getISOYear(week)]

  if (year) {
    return year[getISOWeek(week)] || {}
  }

  return {}
}

export const nextAvailableWeek = () => {
  let week = new Date()
  while (!isAvailable(week)) {
    week = addWeeks(week, 1)
  }
  return week
}

const getWeeksInMonth = initialWeek => {
  let weeksInMonth = []
  const month = getMonth(initialWeek)
  let currentWeek = initialWeek
  while (getMonth(currentWeek) == month) {
    weeksInMonth.push(currentWeek)
    currentWeek = addWeeks(currentWeek, -1)
  }
  currentWeek = addWeeks(initialWeek, 1)
  while (getMonth(currentWeek) == month) {
    weeksInMonth.push(currentWeek)
    currentWeek = addWeeks(currentWeek, 1)
  }

  console.log(weeksInMonth)
  return weeksInMonth.sort()
}

export const nextAvailabilityInWords = () => {
  const nextAvailability = nextAvailableWeek()
  const month = format(
    nextAvailability,
    isThisISOYear(nextAvailability) ? "MMMM" : "MMMM YYYY"
  )
  const weeks = getWeeksInMonth(nextAvailability)
  const nextAvailableWeekMonthIndex = weeks.indexOf(nextAvailability)
  console.log("Next Available Week Month Index", nextAvailableWeekMonthIndex)
  let ordinal = ""
  if (nextAvailableWeekMonthIndex == weeks.length - 1) {
    ordinal = "last"
  } else {
    switch (nextAvailableWeekMonthIndex) {
      case 0:
        ordinal = "first full"
        break
      case 1:
        ordinal = "second full"
        break
      case 2:
        ordinal = "third full"
        break
      case 3:
        ordinal = "fourth full"
        break
    }
  }

  return `${ordinal} week in ${month}`
}
