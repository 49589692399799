import React from "react"
import Week from "./Week"
import Month from "./Month"
import YearPicker from "./YearPicker"
import Responsive from "react-responsive"
import { isAvailable, nextAvailableWeek } from "./helpers"
import { Set } from "immutable"
import { map, chain } from "lodash"
import {
  startOfISOYear,
  getISOYear,
  addISOYears,
  getISOWeek,
  addWeeks,
  getYear,
  getMonth,
  setMonth,
  format,
} from "date-fns"

const pad = (array, withValue, untilSize) => {
  const startFill = array.length
  array.length = untilSize
  array.fill(withValue, startFill)
}

const Mobile = props => <Responsive {...props} maxWidth={1020} />
const Default = props => <Responsive {...props} minWidth={1021} />

const COLUMN_COUNT = 13

class Schedule extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      year: nextAvailableWeek(),
      alt: false,
    }
    this.rebuildCalendar()
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeydown)
    document.addEventListener("keyup", this.handleKeyup)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeydown)
    document.removeEventListener("keyup", this.handleKeyup)
  }

  rebuildCalendar() {
    this.weeks = []
    this.availableMonths = new Set()
    this.monthAreas = {}
    this.gridTemplateAreas = ""

    let currentWeek = startOfISOYear(this.state.year)
    const selectedISOYear = getISOYear(currentWeek)

    while (getISOYear(currentWeek) === selectedISOYear) {
      this.weeks.push(currentWeek)
      if (isAvailable(currentWeek)) {
        this.availableMonths = this.availableMonths.add(
          format(currentWeek, "M-YYYY")
        )
      }
      currentWeek = addWeeks(currentWeek, 1)
    }

    this.gridTemplateAreas = chain(this.weeks)
      .chunk(COLUMN_COUNT)
      .flatMap((weekRow, i) => {
        let months = weekRow.map(week => {
          const monthArea = `m-${getMonth(week)}-${i}`
          this.monthAreas[monthArea] = week
          return monthArea
        })

        let weeks = weekRow.map(week => `w-${getISOWeek(week)}`)

        pad(months, ".", COLUMN_COUNT)
        pad(weeks, ".", COLUMN_COUNT)

        return [months.join(" "), weeks.join(" ")]
      })
      .value()
      .map(row => `"${row}"`)
      .join("\n")
  }

  chunkedWeeks() {
    let chunkedWeeks = new Map()

    for (const week of this.weeks) {
      const key = format(week, "M-YYYY")
      if (!chunkedWeeks.has(key)) {
        chunkedWeeks.set(key, [])
      }
      chunkedWeeks.get(key).push(week)
    }

    return Array.from(chunkedWeeks.values())
  }

  render() {
    this.rebuildCalendar()

    return (
      <React.Fragment>
        <Default>
          <YearPicker
            year={this.state.year}
            onTravelBack={this.handlePreviousYearClick}
            onTravelForward={this.handleNextYearClick}
          />
          <div
            id="calendar"
            css={{
              display: "grid",
              gridTemplateColumns: `repeat(${COLUMN_COUNT}, 60px)`,
              gridGap: "25px 20px",
            }}
            style={{
              gridTemplateAreas: this.gridTemplateAreas,
            }}
          >
            {map(this.monthAreas, (monthDate, area) => (
              <Month
                key={area}
                month={monthDate}
                area={area}
                available={this.availableMonths.has(
                  format(monthDate, "M-YYYY")
                )}
              />
            ))}
            {this.weeks.map((week, i) => (
              <Week
                key={i}
                week={week}
                area={`w-${getISOWeek(week)}`}
                showWeekNumber={this.state.alt}
              />
            ))}
          </div>
        </Default>
        <Mobile>
          <YearPicker
            year={this.state.year}
            onTravelBack={this.handlePreviousYearClick}
            onTravelForward={this.handleNextYearClick}
          />
          <div
            css={{
              display: "grid",
              gridTemplateColumns: "1fr",
              width: "100%",
              gridGap: "25px 20px",
            }}
          >
            {this.chunkedWeeks().map(weeks => {
              const month = getMonth(weeks[0])
              const key = `${month}-${getYear(weeks[0])}`
              return (
                <React.Fragment key={key}>
                  <Month
                    month={setMonth(this.state.year, month)}
                    available={this.availableMonths.has(month)}
                  />
                  <div
                    className="weeks"
                    css={{
                      display: "grid",
                      gridTemplateColumns: "repeat(auto-fit, 60px)",
                      gridGap: 10,
                      justifyContent: "center",
                    }}
                  >
                    {weeks.map((week, i) => (
                      <Week
                        key={i}
                        week={week}
                        showWeekNumber={this.state.alt}
                      />
                    ))}
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        </Mobile>
      </React.Fragment>
    )
  }

  handleNextYearClick = () => {
    this.setState({ year: addISOYears(this.state.year, 1) })
  }

  handlePreviousYearClick = () => {
    this.setState({ year: addISOYears(this.state.year, -1) })
  }

  handleKeydown = e => {
    if (e.keyCode === 18) {
      this.setState({ alt: true })
    }
  }

  handleKeyup = e => {
    if (e.keyCode === 18) {
      this.setState({ alt: false })
    }
  }
}

export default Schedule
