import React from "react"
import { Link, graphql } from "gatsby"
import Helmet from "react-helmet"
import Schedule from "../components/Schedule"
import { nextAvailabilityInWords } from "../components/Schedule/helpers"
import SubscribeForm from "../components/SubscribeForm"
import updatedAt from "../helpers/updated_at"
import Layout from "../layouts"

const HireMePage = ({ data: pageData }) => (
  <Layout>
    <Helmet>
      <meta name="og:title" content="Will Cosgrove | Schedule" />
      <meta name="twitter:title" content="Will Cosgrove | Schedule" />
      <meta name="og:url" content="https://willcosgrove.com/hire-me" />
      <meta name="og:description" content="My work availability for the year" />
    </Helmet>
    <header
      css={{
        gridColumn: 2,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Link to="/" className="button">
        Back
      </Link>
    </header>
    <section css={{ gridColumn: 2, gridRow: 2 }}>
      <p>
        Thanks so much for your interest! I'll give you a quick overview of what
        it looks like to contract with me. I sell my time{" "}
        <strong>by the week</strong>. When you buy a week, you have me full time
        that week, about <strong>40 hours</strong>.
      </p>
      <p>
        One week is <strong>$5,200</strong>, or about $130/hr.
      </p>
      <p>
        Below I have a schedule of my current availability. Gray is unavailable,
        red is the current week, and green is available. Just{" "}
        <a href="mailto:will@willcosgrove.com">shoot me an email</a> if you're
        interested in booking one or more weeks.
      </p>
      <p>My next availability is the {nextAvailabilityInWords()}.</p>
    </section>
    <figure
      css={{
        gridRow: 3,
        gridColumn: "1 / span 3",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Schedule />
    </figure>
    <section id="updatedAt" css={{ gridColumn: 2, gridRow: 4 }}>
      <p className="aside" style={{ textAlign: "center", marginTop: 30 }}>
        Updated {updatedAt(pageData)}
      </p>
    </section>
    <section id="notifications" css={{ gridColumn: 2, gridRow: 5 }}>
      <SubscribeForm />
    </section>
  </Layout>
)

export default HireMePage

export const query = graphql`
  query MyOther {
    file(relativePath: { eq: "schedule.json" }) {
      modifiedTime
    }
  }
`
