import React from "react"
import classNames from "classnames"
import { format, lastDayOfMonth, isFuture } from "date-fns"

const Month = ({ month, area, available }) => {
  const bookable = available && isFuture(lastDayOfMonth(month))
  const color = bookable ? "#103a61" : "#dbdbdb"

  return (
    <div
      className={classNames("month", { available: bookable })}
      css={{ gridArea: area, ...styles }}
      style={{ color }}
    >
      {format(month, "MMMM")}
    </div>
  )
}

const styles = {
  display: "grid",
  justifyContent: "center",
  borderBottom: "1px solid #dbdbdb",
}

export default Month
