import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons"
import { getISOYear } from "date-fns"

const buttonStyles = active => {
  if (active) {
    return { cursor: "pointer" }
  } else {
    return { pointerEvents: "none" }
  }
}

const buttonColor = active => {
  return active ? "#103a61" : "#dbdbdb"
}

const YearPicker = ({
  year,
  onTravelBack = () => {},
  onTravelForward = () => {},
}) => {
  const currentYear = getISOYear(new Date())
  const selectedYear = getISOYear(year)
  const canTravelBack = selectedYear > currentYear
  const canTravelForward = selectedYear <= currentYear + 1

  return (
    <div
      id="yearPicker"
      css={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
    >
      <div
        className="control"
        css={{ padding: "20px", ...buttonStyles(canTravelBack) }}
        onClick={onTravelBack}
      >
        <FontAwesomeIcon
          icon={faAngleLeft}
          size="lg"
          color={buttonColor(canTravelBack)}
        />
      </div>
      <h1 css={{ margin: "0.67em 0", userSelect: "none" }}>{selectedYear}</h1>
      <div
        className="control"
        css={{ padding: "20px", ...buttonStyles(canTravelForward) }}
        onClick={onTravelForward}
      >
        <FontAwesomeIcon
          icon={faAngleRight}
          size="lg"
          color={buttonColor(canTravelForward)}
        />
      </div>
    </div>
  )
}

export default YearPicker
