import React from "react"
import "hint.css"
import { takeWhile, takeRightWhile, every } from "lodash"
import { getScheduleForWeek } from "./helpers"
import { isPast, isThisWeek, format, addDays } from "date-fns"

const WORKING_ALL_DAYS = [true, true, true, true, true]

const getSchedule = week => ({
  booked: false,
  workingDays: WORKING_ALL_DAYS,
  ...getScheduleForWeek(week),
})

const AVAILBLE_COLOR = "#50e59e"
const UNAVAILBLE_COLOR = "#dbdbdb"
const CURRENT_COLOR = "#9f3b29"

class Week extends React.Component {
  state = {}

  static getDerivedStateFromProps(props) {
    return { schedule: getSchedule(props.week) }
  }

  render() {
    const { week, area } = this.props

    if (this.shouldRenderPartialWeek()) {
      return this.renderPartialWeek()
    }

    return (
      <div
        className="hint--top hint--rounded"
        aria-label={this.renderDateRange()}
        style={{ gridArea: area }}
        css={{ ...styles.week, ...this.renderColors() }}
      >
        {this.renderWeekNumber(week)}
      </div>
    )
  }

  shouldRenderPartialWeek() {
    const { week } = this.props
    const { schedule } = this.state

    return (
      !schedule.booked &&
      !isThisWeek(week) &&
      !isPast(week) &&
      !every(schedule.workingDays)
    )
  }

  renderPartialWeek() {
    const { week } = this.props
    const { schedule } = this.state

    return (
      <div
        className="hint--top hint--rounded"
        aria-label={this.renderDateRange()}
        css={styles.partialWeek.container}
      >
        <div css={styles.partialWeek.days}>
          {schedule.workingDays.map((amWorking, index) => (
            <div key={index} css={styles.partialWeek.daySliver(amWorking)} />
          ))}
        </div>
        <div css={styles.partialWeek.dateBox}>
          {this.renderWeekNumber(week)}
        </div>
      </div>
    )
  }

  renderDateRange() {
    const { week } = this.props
    const { schedule } = this.state

    const startOffset = takeWhile(schedule.workingDays, amWorking => !amWorking)
      .length
    const endOffset = takeRightWhile(
      schedule.workingDays,
      amWorking => !amWorking
    ).length

    const monday = format(addDays(week, startOffset), "M/D")
    const friday = format(addDays(week, 4 - endOffset), "M/D")

    return `${monday} - ${friday}`
  }

  renderColors() {
    const { week, showWeekNumber } = this.props
    const { schedule } = this.state
    if (process.env.NODE_ENV == "development") {
      if (showWeekNumber && schedule.booked) {
        return { backgroundColor: schedule.color, color: "#fff" }
      }
    }
    let backgroundColor = AVAILBLE_COLOR
    let color = "rgba(0,0,0,0.4)"
    if (isThisWeek(week)) {
      backgroundColor = CURRENT_COLOR
      color = "#fff"
    } else if (isPast(week) || schedule.booked) {
      backgroundColor = UNAVAILBLE_COLOR
    }

    return { backgroundColor, color }
  }

  renderWeekNumber(week) {
    if (this.props.showWeekNumber) {
      return format(week, "W")
    } else {
      return format(week, "D")
    }
  }
}

const styles = {
  week: {
    height: 60,
    borderRadius: 6,
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
  },
  partialWeek: {
    container: {
      position: "relative",
      height: 60,
      fontSize: 20,
    },
    days: {
      display: "grid",
      gridTemplateColumns: `repeat(5, 1fr)`,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      overflow: "hidden",
      borderRadius: 6,
    },
    daySliver: amWorking => ({
      width: "100%",
      height: "100%",
      backgroundColor: amWorking ? AVAILBLE_COLOR : UNAVAILBLE_COLOR,
    }),
    dateBox: {
      display: "flex",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      color: "rgba(0,0,0,0.4)",
    },
  },
}

export default Week
